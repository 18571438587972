import { useMemoizedContentGetter } from 'utils/contentful'
import { MediaImageStandard, Typography } from 'components/Contentful'
import Section from 'components/Section'
import { BREAKPOINTS } from 'constants/viewport'
import useSsrViewportCheck from 'hooks/useSsrViewportCheck/useSsrViewportCheck'
import { Button } from '@achieve/ascend'
import { useGraduate } from 'providers/GraduateProvider'

const Bubble = ({ text, style }) => {
  if (!text) return null

  return (
    <Typography
      className='whitespace-nowrap py-1 px-2 lg:py-2 lg:px-3 absolute'
      content={text}
      style={style}
    />
  )
}

const WallOfFameHero = ({ content }) => {
  const {
    wallOfFameHeroTitlePublic,
    wallOfFameHeroSubtitlePublic,
    wallOfFameHeroTitle,
    wallOfFameHeroSubtitle,
    wallOfFameHeroImage,
    wallOfFameHeroHashtag,
    wallOfFameHeroDisclaimer,
    wallOfFameHeroButton,
    wallOfFameHeroBubbles,
  } = useMemoizedContentGetter(content, [
    'wallOfFameHeroTitlePublic',
    'wallOfFameHeroSubtitlePublic',
    'wallOfFameHeroTitle',
    'wallOfFameHeroSubtitle',
    'wallOfFameHeroButton',
    'wallOfFameHeroImage',
    'wallOfFameHeroHashtag',
    'wallOfFameHeroDisclaimer',
    'wallOfFameHeroBubbles',
  ])
  const { graduate } = useGraduate()

  const isMobile = useSsrViewportCheck(content?.isMobileUA, BREAKPOINTS.lg)
  const mediaImageContent = !isMobile
    ? wallOfFameHeroImage?.mediaContent
    : wallOfFameHeroImage?.mobileMediaContent || wallOfFameHeroImage?.mediaContent

  const handleScroll = () => {
    const section = document.getElementById("targetSection");
    const scrollableDiv = document.getElementById("scrollableDiv");

    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
    if (scrollableDiv) {
      scrollableDiv.scrollTo({ left: 0, behavior: "smooth" });
    }
  };

  const title = graduate ? wallOfFameHeroTitle : wallOfFameHeroTitlePublic
  const subtitle = graduate ? wallOfFameHeroSubtitle : wallOfFameHeroSubtitlePublic

  return (
    <Section
      data-testid="wall-of-fame-hero-section"
      className="mx-5 flex flex-col lg:flex-row justify-start lg:items-center lg:justify-center gap-10 lg:gap-24 mt-10 lg:mt-4 overflow-hidden"
      contain={false}
    >
      <div className="flex flex-col w-full lg:w-[528px] gap-4">
        {wallOfFameHeroHashtag?.textContent && (
          <Typography
            content={wallOfFameHeroHashtag.textContent}
            className="my-2 lg:my-0 uppercase tracking-[0.2em] text-sm text-center lg:text-left font-bold text-[#5C708A]"
          />
        )}
        {title?.textContent && (
          <Typography
            content={title.textContent}
            className="text-center lg:text-left text-[36px] leading-[43px] lg:text-[56px] lg:leading-[67px]"
          />
        )}
        {subtitle?.textContent && (
          <Typography
            content={subtitle.textContent}
            className="text-center lg:text-left text-base lg:text-lg leading-[25px]"
          />
        )}
        {wallOfFameHeroButton?.textContent && graduate && graduate.isPublic && (
          <Button
            onClick={handleScroll}
            className="w-full lg:w-fit mt-0 lg:mt-4 bg-[#CB000E] hover:bg-[#1A2D54] px-6 py-3 rounded-md"
          >
            <Typography
              content={wallOfFameHeroButton.textContent}
              className="text-white whitespace-nowrap text-lg font-bold"
            />
          </Button>
        )}
      </div>
      <div className="flex flex-col justify-center items-center gap-4">
        <div className="relative w-[216px] h-[216px] lg:w-[400px] lg:h-[400px]">
          <MediaImageStandard
            content={mediaImageContent}
            layout="fixed"
            priority={true}
            quality={isMobile ? 100 : 75}
            alt={wallOfFameHeroImage?.mediaAltText || 'wall-of-fame-hero-image'}
            unoptimized={true}
          />
          {
            wallOfFameHeroBubbles?.jsonContent?.bubbles?.map((bubble, index) => (
              <Bubble
                key={index}
                text={bubble?.text}
                style={isMobile ? bubble?.style : bubble?.lgStyle}
              />
            ))
          }
        </div>
        <Typography
          content={wallOfFameHeroDisclaimer.textContent}
          className="text-[#5C708A] text-xs lg:text-sm font-normal max-w-[490px] text-center"
        />
      </div>
    </Section>
  )
}

export { WallOfFameHero }
export default WallOfFameHero
