import { useMemoizedContentGetter } from 'utils/contentful'
import Section from 'components/Section'
import { MediaImageStandard, Typography, LinkButton } from 'components/Contentful'
import { BLOCKS } from '@contentful/rich-text-types'
import { Carousel } from 'components/Carousels'
import classNames from 'classnames'
import { useGraduate } from 'providers/GraduateProvider'

const ItemComponentCarousel = ({ item, index }) => {
  return (
    <div>
      <div className="mt-2 flex items-center justify-center relative">
        <div>
          <MediaImageStandard
            content={item?.image}
            layout="fixed"
            className="w-full h-auto max-w-md lg:max-w-xl"
            height={500}
            width={629}
            alt={item?.image?.title}
            unoptimized={true}
            priority={index === 0}
            quality={100}
          />
          {item?.config?.bubbles?.map((bubble, index) => (
            <Typography
              key={index}
              className={classNames(
                'whitespace-nowrap py-1 px-2 lg:py-2 lg:px-3 absolute',
                bubble?.style
              )}
              content={bubble?.text}
            />
          ))}
        </div>
      </div>
      <div className="mt-2 flex items-center justify-center">
        <div className="max-w-md lg:pb-7">
          {item?.disclaimer && <Typography content={item?.disclaimer} variant="bodyXs" />}
        </div>
      </div>
    </div>
  )
}

function HeroSliderModule({
  grid,
  trustPilot,
  title,
  subTitle,
  graduateTitle,
  graduateSubTitle,
  eyebrow,
  config,
  button,
  graduateButton,
}) {
  const { graduate } = useGraduate()
  const items = grid?.gridItemsCollection?.items || []
  const backgroundConfig = config?.jsonContent?.sectionBackground ?? 'bgDefault'
  const finalTitle = graduate && graduateTitle ? graduateTitle : title
  const finalSubtitle = graduate && graduateSubTitle ? graduateSubTitle : subTitle
  const variant = config?.jsonContent?.variant ?? 'default'

  return (
    <Section variant={backgroundConfig}>
      <div className="flex flex-col lg:flex-row items-center justify-between p-0 md:p-4 pt-12 lg:pt-4 lg:px-0">
        <div
          className={
            variant === 'xl' ? 'max-w-[580px] xl:min-w-[580px]' : 'max-w-[520px] xl:min-w-[520px]'
          }
        >
          {eyebrow?.textContent && (
            <Typography
              content={eyebrow?.textContent}
              variant="labelSm"
              color="gray"
              fontWeight="bold"
              className="uppercase pb-4 tracking-eyebrow  text-center md:text-left"
            />
          )}
          {finalTitle?.textContent && (
            <Typography
              content={finalTitle?.textContent}
              variant="displayXl"
              mobileVariant="displayLg"
              responsive={true}
              className="pb-4 md:text-[68px]  text-center md:text-left"
            />
          )}
          {finalSubtitle?.textContent && (
            <Typography
              content={finalSubtitle?.textContent}
              variant="bodyLg"
              mobileVariant="labelMd"
              responsive={true}
              className="pb-4 font-normal text-center md:text-left"
              fontWeight="regular"
            />
          )}

          {button && (
            <LinkButton
              track={{
                ...{
                  list_name: 'Hero Slider CTA click',
                  click_id: button?.linkText?.json?.content?.[0]?.content[0]?.value,
                  click_text: button?.linkText?.json?.content?.[0]?.content[0]?.value,
                  click_url: button?.linkHref,
                  track_event: 'internal_campaign_click',
                  event_action: 'button_click',
                  event_type: 'button_click',
                },
                ...button.event,
              }}
              typographicOptions={{
                variantOverride: { [BLOCKS.PARAGRAPH]: 'bodyLg' },
                fontWeight: 'bold',
              }}
              variant="contained"
              color="primary"
              className="shadow-md w-full lg:w-auto mt-2"
              content={button}
            />
          )}
          {graduateButton && graduate && (
            <LinkButton
              track={{
                ...{
                  list_name: 'Hero Slider Graduate CTA click',
                  click_id: graduateButton?.linkText?.json?.content?.[0]?.content[0]?.value,
                  click_text: graduateButton?.linkText?.json?.content?.[0]?.content[0]?.value,
                  click_url: graduateButton?.linkHref,
                  track_event: 'internal_campaign_click',
                  event_action: 'button_click',
                  event_type: 'button_click',
                },
                ...graduateButton.event,
              }}
              typographicOptions={{
                variantOverride: { [BLOCKS.PARAGRAPH]: 'bodyLg' },
                fontWeight: 'bold',
              }}
              variant="contained"
              color="primary"
              className="w-full lg:w-fit mt-0 lg:mt-4 bg-[#CB000E] hover:bg-[#1A2D54] hover:text-white px-6 py-3 rounded-md"
              content={graduateButton}
            />
          )}
          {trustPilot && (
            <div className="mt-7 flex items-center justify-center lg:justify-start">
              <MediaImageStandard
                content={trustPilot?.mediaContent}
                layout="fixed"
                alt={trustPilot?.mediaAltText}
                unoptimized={true}
                priority={true}
                quality={100}
              />
            </div>
          )}
        </div>
        <div className="relative my-4 ">
          <Carousel
            autoPlay={true}
            carouselItems={items}
            itemComponent={ItemComponentCarousel}
            variant="preview-full"
            withIndicators={items?.length > 1}
            disableArrows={true}
            classNameIndicatorDots="flex bg-[#3300ff] mt-8 mb-8 lg:hidden hover:cursor-pointer"
            idPrefix="hero-slider"
            interval={10000}
            navigateOnClick={true}
            scrollOnChange={false}
            classNameContainer="max-w-[509px] lg:max-w-none "
            className="p-0 sm:pl-4"
          />
        </div>
      </div>
    </Section>
  )
}

function HeroSlider({ content }) {
  const {
    grid,
    trustPilot,
    title,
    subTitle,
    graduateTitle,
    graduateSubTitle,
    eyebrow,
    config,
    button,
    graduateButton,
  } = useMemoizedContentGetter(content, [
    'grid',
    'trustPilot',
    'title',
    'subTitle',
    'graduateTitle',
    'graduateSubTitle',
    'eyebrow',
    'config',
    'button',
    'graduateButton',
  ])
  return (
    <HeroSliderModule
      title={title}
      subTitle={subTitle}
      eyebrow={eyebrow}
      grid={grid}
      trustPilot={trustPilot}
      config={config}
      button={button}
      graduateTitle={graduateTitle}
      graduateSubTitle={graduateSubTitle}
      graduateButton={graduateButton}
    />
  )
}

export { HeroSlider, HeroSliderModule }
export default HeroSlider
